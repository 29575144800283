import { SentryError } from '@ifixit/sentry';

/**
 * Represents an abstract location that MediaItem can be attached to.
 *
 * The main interaction is done by passing options.customAttach = function()
 * then calling MediaManager.browse(mediaTarget);
 */
export const MinimalMediaTarget = new Class({
   name: 'MinimalMediaTarget',
   Implements: [Events],
   options: {},

   initialize: function (options) {
      Object.append(this.options, options);
      if (!this.options.accepts) {
         throw new SentryError("Must provide an 'accepts' option");
      }
   },

   /**
    * Called by MediaManager after an MediaItem is chosen.
    */
   attachItem: function (mediaItem, finished) {
      let target = this;
      function complete(old, success, message) {
         if (success) {
            target.fireEvent('itemChanged', [mediaItem]);
         }
         if (finished) {
            finished.apply(target, Array.convert(arguments));
         }
      }

      if (!mediaItem.isReady()) {
         complete(null, false, "Can't be placed until processing is finished");
         return;
      }

      if (mediaItem.data.filter_state() === false) {
         complete(null, false, "Can't place media of this type.");
         return;
      }

      let customAttach = this.options.customAttach;
      if (customAttach) {
         customAttach(mediaItem, complete);
      } else {
         complete(null, true);
      }
   },

   /**
    * Returns the name of the filter collection that a media Item must pass
    * to be attached to this target.
    *
    * See: MediaFilters.php
    */
   getMediaFilterName: function () {
      return this.options.accepts;
   },
});
