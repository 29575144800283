import {
   Box,
   Flex,
   Heading,
   HStack,
   Image,
   LinkBox,
   LinkOverlay,
   SimpleGrid,
   Text,
} from '@chakra-ui/react';
import { _js } from '@ifixit/localize';
import { initializeChakraComponent } from 'Shared/chakra-initialize';

interface CategoryProps {
   imgUrl: string;
   url: string;
   displayTitle: string;
}

function HomePageGuideCategories(categories: Array<CategoryProps>) {
   return (
      <Flex
         direction="column"
         mx="6"
         mt={{ base: '78px', lg: '104px' }}
         mb={{ base: 12, lg: 16 }}
         alignItems="center"
         textAlign="center"
      >
         <Heading m="0" fontSize="4xl">
            {_js('Repair guides for every thing, written by everyone.')}
         </Heading>
         <Text mt="6" mb={{ base: 6, lg: 16 }} fontSize="lg">
            {_js(
               'We help thousands of people repair their things every day. Every time you fix something, you help the planet.'
            )}
         </Text>
         <CategoriesGrid categories={categories} />
      </Flex>
   );
}

const CategoriesGrid = ({ categories }: { categories: Array<CategoryProps> }) => (
   <SimpleGrid
      spacing={{ base: 3, md: 4, lg: 6 }}
      columns={{ sm: 2, md: 3, lg: 4 }}
      width="100%"
      maxWidth="1216px"
   >
      {Object.values(categories).map((c: any, index: number) => (
         <Category key={index} imgUrl={c.imgUrl} url={c.url} displayTitle={c.displayTitle} />
      ))}
   </SimpleGrid>
);

const Category = ({ imgUrl, url, displayTitle }: CategoryProps) => (
   <LinkBox
      borderRadius="8px"
      borderWidth={1}
      bgColor="white"
      h="64px"
      transition="border-color 0.12s"
      _hover={{
         borderColor: 'blue.500',
      }}
   >
      <HStack alignItems="center" spacing={0}>
         <Box w="80px" h="64px" p="6px" flexShrink={0}>
            <Image
               alt={displayTitle}
               src={imgUrl}
               htmlWidth="67"
               htmlHeight="50"
               loading="lazy"
               borderWidth={1}
               borderRadius="4px"
            />
         </Box>
         <Flex pr={4} alignItems="center" textAlign="left">
            <LinkOverlay
               href={url}
               color="gray.900"
               _hover={{ textDecoration: 'none' }}
               _visited={{ color: 'gray.900' }}
            >
               <Text m={0} fontSize="lg" fontWeight="semibold">
                  {displayTitle}
               </Text>
            </LinkOverlay>
         </Flex>
      </HStack>
   </LinkBox>
);

initializeChakraComponent('HomePageGuideCategories', HomePageGuideCategories);
