import { initializeChakraComponent } from 'Shared/chakra-initialize';
import { StatsSection } from '@ifixit/footer';
import { Suspense } from 'react';
import { Skeleton } from '@chakra-ui/react';

function KPIDisplay(stats: any) {
   return (
      <Suspense fallback={<Skeleton minHeight={{ base: '405.38px', sm: '172.4px' }} />}>
         <StatsSection data={stats} />
      </Suspense>
   );
}

initializeChakraComponent('KPIDisplay', KPIDisplay);
