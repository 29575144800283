import 'Guide/entrypoint/guidepage';
import './home_page_ctas';
import './home_page_guide_categories';
import './home_page_hero';
import './partnerships_display';
import './kpi_display';
import './search_hero';
import './featured_documents';
import 'Shared/Campaigns/campaign-banner-wp';
import trackInPiwikAndGA from 'Shared/Analytics/CombinedGAPiwik';

onDomReady(() => {
   const setElementsAsPresentation = function (elements) {
      elements.set('tabindex', -1);
      elements.set('role', 'presentation');
   };
   setElementsAsPresentation($$('.js-presentation-element'));

   $$('.track-tool').addEvent('click', function (ev) {
      trackInPiwikAndGA({
         eventCategory: 'Featured Products - International Homepage',
         eventAction: `Featured on International Homepage - ${this.getParent('.tool-feature').get(
            'data-toolname'
         )}`,
      });
   });

   $$('.view-more').addEvent('click', function (ev) {
      trackInPiwikAndGA({
         eventCategory: 'Featured Products - International Homepage',
         eventAction: `Featured on International Homepage - ${this.getParent('.tool-feature').get(
            'data-toolname'
         )} - View More`,
      });
   });
});
